


















import { mapActions, mapState, mapGetters } from 'vuex';
const DashboardTokenStatistic = (): Promise<any> =>
  import(
    /* webpackMode: "lazy" */ '@/views/DashboardViews/dashboard-view/DashboardTokenStatistic.vue'
  );
const DashboardGraphs = (): Promise<any> =>
  import(
    /* webpackMode: "lazy" */ '@/views/DashboardViews/dashboard-view/DashboardGraphs.vue'
  );
const DashboardRewards = (): Promise<any> =>
  import(
    /* webpackMode: "lazy" */ '@/views/DashboardViews/dashboard-view/DashboardRewards.vue'
  );
const DashboardPerformance = (): Promise<any> =>
  import(
    /* webpackMode: "lazy" */ '@/views/DashboardViews/dashboard-view/DashboardPerfomance.vue'
  );

export default {
  name: 'DashboardView',
  components: {
    DashboardTokenStatistic,
    DashboardGraphs,
    DashboardRewards,
    DashboardPerformance
  },
  data(): any {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('Dashboard', ['operatorWallet']),
    ...mapGetters('Onboarding', ['operatorId', 'isMobile'])
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.loading = true;
          Promise.all([
            this.getAccountsBalance(),
            this.getStakingPrograms()
          ]).finally(() => {
            this.loading = false;
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Dashboard', ['getAccountsBalance']),
    ...mapActions('StakingPrograms', ['getStakingPrograms'])
  }
};
